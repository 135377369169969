import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
import { ModifyPasswordService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
import * as Utils from '@/common/utils';
let ModifyPasswordUpdate = class ModifyPasswordUpdate extends Vue {
    constructor() {
        super(...arguments);
        /**
         * 表单
         */
        this.form = {
            newPassword: '',
            comfirmPassword: '',
        };
        this.typeOptions = [{
                label: '数字',
                value: 'digit',
            }, {
                label: '小写字母',
                value: 'lowercaseLetter',
            }, {
                label: '大写字母',
                value: 'uppercaseLetter',
            }, {
                label: '英文特殊字符（除空格）',
                value: 'specialCharacter',
            }];
        /**
         * 表单校验规则
         */
        this.rules = {
            newPassword: [{
                    pattern: /^[^\s]*$/,
                    message: '请不要输入空格',
                    trigger: 'blur',
                }, {
                    max: 32,
                    message: '长度不能超过32位',
                    trigger: 'blur',
                }, { validator: this.newPasswordValid, trigger: 'blur' }],
            comfirmPassword: [
                { validator: this.comfirmPasswordValid, trigger: 'blur' },
            ],
        };
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    newPasswordValid(rule, value, callback) {
        // const reg = /^[A-Z](?=.*[0-9].*)(?=.*[a-z].*).{5,19}$/;
        if (!this.passRuleData.passwordRuleStatus) {
            const reg = /^[a-zA-Z](?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[!@#$%^&*_?,.~].*).{8,32}$/;
            if (!value) {
                callback(new Error('密码不能为空！'));
            }
            else if (!reg.test(value)) {
                callback(new Error('8-32位，以字母开头，包含大写字母、小写字母、数字和特殊字符!'));
            }
            return;
        }
        if (this.passRuleData.regularExpression) {
            const reg = this.evil('/' + this.passRuleData.regularExpression + '/');
            if (!value) {
                callback(new Error('密码不能为空！'));
            }
            else if (!reg.test(value)) {
                let str = '';
                this.typeOptions.forEach(item => {
                    if (this.passRuleData[item.value]) {
                        if (str.length === 0) {
                            str += item.label;
                        }
                        else {
                            str += '、' + item.label;
                        }
                    }
                });
                const tip = `至少包含${str}，最少${this.passRuleData.passwordLength}位，最高32位`;
                callback(new Error(tip));
            }
            else {
                callback();
            }
        }
        else {
            const reg = /^[A-Z](?=.*[0-9].*)(?=.*[a-z].*).{5,19}$/;
            if (!value) {
                callback(new Error('密码不能为空！'));
            }
            else if (!reg.test(value)) {
                const tip = '请以大写字母开头,6~20位';
                callback(new Error(tip));
            }
            else if (value.length > 32) {
                callback(new Error('密码长度不能超过32位'));
            }
            else {
                callback();
            }
        }
    }
    evil(fn) {
        // 本质是eval()，防止eslint报错
        const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
        return new Fn('return ' + fn)();
    }
    comfirmPasswordValid(rule, value, callback) {
        if (!value) {
            callback(new Error('密码不能为空！'));
        }
        else if (value !== this.form.newPassword) {
            callback(new Error('两次密码输入不一致，请重新输入!'));
        }
        else {
            callback();
        }
    }
    /**
     * 确认回调
     */
    submit() {
        this.$refs.form.validate(valid => {
            if (valid) {
                this.requestService();
            }
        });
    }
    async requestService() {
        const newPassword = RsaEncrypt(this.form.newPassword, this.rsaKey.key);
        const params = {
            oldPassword: this.oldPassword,
            newPassword,
            index: this.rsaKey.index,
        };
        this.$emit('validingEvent', true);
        ModifyPasswordService.modifyPassword(params, this.baseUserId).then((res) => {
            const { success } = res;
            if (success) {
                this.$message.success('设置成功！');
                setTimeout(() => {
                    this.$emit('updateHandler');
                }, 1000);
            }
            else {
                this.$message.warning('设置新密码失败！');
            }
        }).finally(() => {
            this.$emit('validingEvent', false);
        });
    }
};
__decorate([
    Prop(String)
], ModifyPasswordUpdate.prototype, "baseUserId", void 0);
__decorate([
    Prop(String)
], ModifyPasswordUpdate.prototype, "oldPassword", void 0);
__decorate([
    Prop({ default: () => ({}) })
], ModifyPasswordUpdate.prototype, "rsaKey", void 0);
__decorate([
    Prop()
], ModifyPasswordUpdate.prototype, "passRuleData", void 0);
__decorate([
    Watch('form', { deep: true })
], ModifyPasswordUpdate.prototype, "onFormChange", null);
ModifyPasswordUpdate = __decorate([
    Component({
        name: 'ModifyPasswordUpdate',
    })
], ModifyPasswordUpdate);
export default ModifyPasswordUpdate;
